import React from "react";
import { useRef, useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

//Multiple names without special characters or numbers
const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
//Minimum three characters, maximum 24, beginning with a letter
const USER_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
//Minimum eight characters, maximum 24
const PASSWORD_REGEX = /^.{8,24}$/;




export const Registration = () => {

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validEmail, setvalidEmail] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [name, setName] = useState('');
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [surname, setSurname] = useState('');
    const [validSurname, setValidSurname] = useState(false);
    const [surnameFocus, setSurnameFocus] = useState(false)

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [duplicate, setDuplicate] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, [])

    //Verificare name regex
    useEffect(() => {
        const result = NAME_REGEX.test(name);
        setValidName(result);

    }, [name])

    //Verificare cognome regex
    useEffect(() => {
        const result = NAME_REGEX.test(surname);
        setValidSurname(result);

    }, [surname])

    //Verificare user regex
    useEffect(() => {
        const result = USER_REGEX.test(user);
        setvalidEmail(result);

    }, [user])

    //Verificare password regex
    useEffect(() => {
        const result = PASSWORD_REGEX.test(pwd);
        setValidPwd(result);
        const match = pwd === matchPwd;
        setValidMatch(match);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])



    const handleSubmit = async (e) => {
        e.preventDefault();
        const v1 = USER_REGEX.test(user);
        const v2 = PASSWORD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry Error");
            return;
        }
        e.preventDefault();

        fetch('https://backend.clubpass.it/register',
            {
                method: 'POST',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: name, surname: surname, username: user, password: pwd })
            })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 409) {
                    setDuplicate(true);

                }
                else {
                    setSuccess(false);
                    setErrMsg("Invalid Entry Error");
                }
            })
            .then((data) => {

                setSuccess(true);
            })
            .catch((err) => {
                console.error('Error', err);
            });

    }

    return (

        <section>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            <div className="card" style={{ display: "inline-block"}}>
                <h1>Registrazione</h1>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <div className="form__group field" style={{ width: "100px" }}>
                            <input
                                type="text"
                                id="name"
                                className="form__field"
                                placeholder="Nome"
                                autoComplete="off"
                                onChange={(e) => setName(e.target.value)}
                                required
                                aria-invalid={validName ? "false" : "true"}
                                onFocus={() => setNameFocus(true)}
                                onBlur={() => setNameFocus(false)}
                                aria-describedby="namenote"
                                style={{ width: "100px", marginRight: "5px" }}
                            />
                            <label htmlFor="name" className="form__label">Nome</label>
                        </div>

                        <div className="form__group field" style={{ width: "100px" }}>
                            <input
                                type="text"
                                id="surname"
                                className="form__field"
                                placeholder="Cognome"
                                autoComplete="off"
                                onChange={(e) => setSurname(e.target.value)}
                                required
                                aria-invalid={validSurname ? "false" : "true"}
                                onFocus={() => setSurnameFocus(true)}
                                onBlur={() => setSurnameFocus(false)}
                                aria-describedby="surnote"
                                style={{ width: "100px", marginLeft: "5px" }}
                            />
                            <label htmlFor="surname" className="form__label">Cognome</label>
                        </div>

                    </div>
                    <p id="namenote" className={(nameFocus && name && !validName) ? "istructions" : "offscreen"}>
                        Il nome inserito non è valido
                    </p>
                    <p id="surnote" className={(surnameFocus && surname && !validSurname) ? "istructions" : "offscreen"}>
                        Il cognome inserito non è valido
                    </p>
                    <div className="form__group field">
                        <input
                            type="text"
                            id="email"
                            className="form__field"
                            placeholder="Email"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            required
                            aria-invalid={validEmail ? "false" : "true"}
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                            aria-describedby="uidnote"
                            style={{ "width": "180px" }}
                        />
                        <label htmlFor="email" className="form__label">Email</label>
                    </div>
                    <p id="uidnote" className={(userFocus && user && !validEmail) ? "istructions" : "offscreen"}>
                        Da 4 a 24 caratteri.<br />
                        Deve iniziare con una lettera.<br />
                        Si accettano lettere, numeri e underscore
                    </p>


                    <div>
                        <div className="form__group field">
                            <input
                                type="password"
                                id="password"
                                className="form__field"
                                placeholder="Password"
                                onChange={(e) => setPwd(e.target.value)}
                                required
                                aria-invalid={validPwd ? "false" : "true"}
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                aria-describedby="pwdnote"
                                style={{ "width": "180px" }}
                            />
                            <label htmlFor="password" className="form__label">Password</label>
                        </div>
                        <p id="pwdnote" className={pwdFocus && !validPwd ? "istructions" : "offscreen"}>
                            Da 8 a 24 caratteri.<br />
                        </p>
                    </div>

                    <div hidden={!validPwd}>
                        <div className="form__group field">
                            <input
                                type="password"
                                id="confirm_pwd"
                                className="form__field"
                                placeholder="Conferma Password"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                required
                                aria-invalid={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                                style={{ "width": "180px" }}
                            />
                            <label htmlFor="confirm_pwd" className="form__label">Conferma Password</label>
                        </div>
                        <p id="pwdnote" className={matchFocus && !validMatch ? "istructions" : "offscreen"}>
                            Le password devono corrispondere.
                        </p>
                    </div>  
                    <input
                        type="submit"
                        className="button"
                        value="Registrati"
                        disabled={!validEmail || !validPwd || !validMatch ? true : false}
                    />
                </form>

                <p className={"istructions"} style={{ marginTop: "10px" }} hidden={!success}>
                    {"Registrazione valida, per completarla controlla la tua email " + user}
                </p>
                <p className={"istructions"} style={{ marginTop: "10px" }} hidden={!duplicate}>
                    {"Esiste già un account registrato con email " + user}
                </p>
                <p className="text-small">
                    Già registrato?
                    <span className="line">
                        <Link to='/login' >Login</Link>
                    </span>
                </p>
            </div>
        </section>
    );
}

export default Registration