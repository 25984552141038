import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import ProductList from "../components/ProductList";
import { useState } from "react";
import EventList from "../components/EventList";
import Workers from "../components/Workers";
import EndedEventsList from "./EndedEventsListPage";
import { XAxis, YAxis, Tooltip, Legend, Line, LineChart } from 'recharts';

export const Dashboard = () => {
    const [hiddenProducts, setHiddenProducts] = useState(false);
    const [products, setProducts] = useState(true);
    const [lastEvent, setLastEvent] = useState({});
    const [semiLastEvent, setSemiLastEvent] = useState({});

    const [consumptionsData, setConsumptionsData] = useState([]);

    useEffect(() => {
        fetch("https://backend.clubpass.it/organizer/stats/latest/10", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
            .then((data) => {
                console.log(data.stats);
                // format the data to be used by the chart
                let formattedData = [];
                //sort the data by date
                data.stats.sort((a, b) => {
                    return new Date(a.eventStart) - new Date(b.eventStart);
                });
                data.stats.forEach((stat) => {
                    formattedData.push({
                        // format the date to be displayed in the chart
                        name: new Date(stat.eventStart).toLocaleDateString("it-IT", { day: "numeric", month: "short" }),
                        consumazioni: stat.consumptions,
                        ingressi: stat.entrances
                    })
                });
                setConsumptionsData(formattedData);
                setLastEvent(data.stats[data.stats.length - 1]);
                setSemiLastEvent(data.stats[data.stats.length - 2]);
            })
            .catch((error) => {
                console.log(error);
            });

        fetch("https://backend.clubpass.it/organizer/stats/now", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        })
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            }
            );
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
                //if on mobile, show the elements in a column
                window.innerWidth < 600 ? (
                    <div>
                        <h1>Dashboard</h1>

                        <div className="card">
                            <h2>Gestione eventi</h2>
                            <EventList />
                        </div>

                        <div className="card">
                            <h2>Prodotti</h2>
                            <div>
                                <ProductList />
                            </div>
                        </div>

                        <div className="card">
                            <h2>Personale</h2>
                            <Workers />
                        </div>

                    </div>
                ) : (
                    //if on desktop, show the elements in one wide column
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                        <div style={{ height: "100%", width: 200 }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: window.innerWidth - 200, float: "right" }}>
                            <h1>Dashboard</h1>
                            <div style={{ display: "flex", flexDirection: "row", alignContent: "flex-end", alignItems: "flex-start" }}>
                                <div className="card" style={{ width: "70%", marginRight: "20px" }}>
                                    <h2>Consumazioni</h2>
                                    <LineChart
                                        width={window.innerWidth * 0.4}
                                        height={400}
                                        animationDuration={500}
                                        data={consumptionsData}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <XAxis dataKey="name" fontSize={12} textAnchor="end" />
                                        <YAxis />
                                        <Tooltip contentStyle={{ backgroundColor: "#202020", borderRadius: 10, borderWidth: 0 }} />
                                        <Legend />
                                        <Line type="monotone" dataKey="consumazioni" stroke="#8884d8" strokeWidth={3} />
                                        <Line type="monotone" dataKey="ingressi" stroke="#82ca9d" strokeWidth={3} />x
                                    </LineChart>
                                </div>
                                <div style={{ width: "30%" }}>
                                    <div className="card" style={{ marginTop: 50, marginBottom: 0 }}>
                                        <h2>Ingressi</h2>
                                        <h3 style={{ fontSize: 50, lineHeight: "0.0rem" }}>{lastEvent.entrances}</h3>
                                        <p style={lastEvent.entrances > semiLastEvent.entraces ? { color: "green", backgroundColor: "#c9ffc1", borderRadius: 20 }
                                            :
                                            { color: "red", backgroundColor: "#fed5db", borderRadius: 20 }
                                        }>{
                                                semiLastEvent.entrances ? (
                                                    lastEvent.entrances > semiLastEvent.entrances ? (
                                                        <span>+{Math.round((lastEvent.entrances - semiLastEvent.entrances) / semiLastEvent.entrances * 100)}%</span>
                                                    ) : (
                                                        <span>-{Math.round((semiLastEvent.entrances - lastEvent.entrances) / semiLastEvent.entrances * 100)}%</span>
                                                    )
                                                ) : (
                                                    <span>0%</span>
                                                )
                                            }</p>
                                    </div>
                                    <div className="card" style={{ marginTop: 50, marginBottom: 0 }}>
                                        <h2>Consumazioni</h2>
                                        <h3 style={{ fontSize: 50, lineHeight: "0.0rem" }}>{lastEvent.consumptions}</h3>
                                        <p style={lastEvent.consumptions > semiLastEvent.consumptions ? { color: "green", backgroundColor: "#c9ffc1", borderRadius: 20 }
                                            :
                                            { color: "red", backgroundColor: "#fed5db", borderRadius: 20 }
                                        }>{
                                                semiLastEvent.consumptions ? (
                                                    lastEvent.consumptions > semiLastEvent.consumptions ? (
                                                        <span>+{Math.round((lastEvent.consumptions - semiLastEvent.consumptions) / semiLastEvent.consumptions * 100)}%</span>
                                                    ) : (
                                                        <span>-{Math.round((semiLastEvent.consumptions - lastEvent.consumptions) / semiLastEvent.consumptions * 100)}%</span>
                                                    )
                                                ) : (
                                                    <span>0%</span>
                                                )
                                            }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <h2>Gestione eventi</h2>
                                <EventList />
                            </div>

                            <div className="card">
                                <h2>Prodotti</h2>
                                <div>
                                    <ProductList />
                                </div>
                            </div>

                            <div className="card" style={{ width: window.innerWidth - 400, maxWidth: "unset" }}>
                                <h2>Personale</h2>
                                <Workers />
                            </div>

                        </div>
                    </div>
                )
            }
            <Navbar current="dashboard" />
        </div >
    );

};
